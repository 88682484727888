.station {
  margin-left: 0.5rem;
}

.station__title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 100;
  margin-top: 3rem;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
}

.station__list {
  width: 100%;
  list-style-type: none;
  padding: 0;
  margin-left: 0rem;
  display: inline-block;
  text-align: left;
  transform: translateZ(0);
}

@media screen and (max-width: 1679px) {
  .station {
    margin-left: 0;
  }

  .station__title {
    margin-top: 1.65rem;
    font-size: 1.1rem;
    margin-left: 0;
  }
}
