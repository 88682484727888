.quotes__title {
  font-size: 1.3rem;
  font-style: italic;
  font-weight: 100;
  margin-top: 3rem;
}

.quotes__list {
  padding: 0;
  text-align: left;
}

.quotes__list h2 {
  margin-bottom: 0;
  font-size: 1.2rem;
  font-weight: bold;
  padding-top: 1rem;
  font-style: italic;
}

.modal-content .quotes__list p {
  margin: 1rem 0;
}

.quotes__list ul {
  list-style-type: none;
  width: 100%;
  padding: 0;
  display: inline-block;
  text-align: left;
  transform: translateZ(0);
  font-style: italic;
}

@media screen and (max-width: 992px) {
  .quotes__list  {
     background: none;
     min-height: 0;
  }

  .quotes__list ul {
    padding: 0;
  }
}

.quotes__item {
  width: 100%;
  background: #ffffff0d;
  padding: 0.5rem;
  border-radius: 0.2rem;
  margin-bottom: 0.3rem;
}

.quotes__item--motivation {
  padding: 1rem;
  font-style: italic;
  margin-top: 1rem;
}

.quotes__item button {
  float: right;
}

.quotes__item__content {
  display: inline-block;
  width: calc(100% - 3rem);
}

.quotes__item__content--custom {
  font-weight: bold;
}


.quotes__item:last-child:after {
  border-bottom: 0;
}

.quotes__item:last-child {
  border-bottom: 0;
}

.quotes__button {
  margin-top: 0rem !important;
  margin-bottom: 3rem !important;
}

.quotes__motivation-headline {
  border-top: solid 1px rgba(255, 255, 255, .1);
  padding-top: 2rem !important;
}

.rc-switch, .rc-switch-alternate  {
  background: rgba(255, 255, 255, .3);
  border: 1px solid rgba(255, 255, 255, .05);
}
.rc-switch-checked {
  background-color: #342c4d;
  border: 1px solid #342c4d;
}

.rc-switch-alternate.rc-switch-checked {
  background-color: #ff0046;
  border: 1px solid #ff0046;
}
