
.header-pro {
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 0;
  color: black;
  z-index: 1;
  text-transform: uppercase;
  line-height: 1.2rem;
  font-style: italic;
}

.header-pro__action-container {
  margin: 0;
  margin-left: 1rem;
  padding-right: 1rem;
  display: flex;
}

.header-pro__badge {
  color: white;
  background: #ff0046;
  font-weight: bold;
  font-style: italic;
  padding: 0.20rem 0.75rem;
  padding-top: 0.18rem;
  font-size: 0.6875rem;
  border-radius: 1rem;
  display: inline-block;
  margin: auto 0;
}

.header-pro__action {
  display: block;
  font-size: 1.25rem;
  font-weight: bold;
  line-height: 1.4rem;
  padding-right: 0.66rem;
}

.header-pro__actions {
  display: none;
}

@media screen and (max-width: 1280px) {
  .header-pro__action {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media screen and (min-width: 1180px) {
  .header-pro__actions {
    display: block;
  }
}
