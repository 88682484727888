.promotion__header {
  text-transform: uppercase;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.66rem;
}

.promotion__icon {
  margin: 0;
  margin-right: 1rem;
  display: inline-block;
  line-height: 1.4rem;
  vertical-align: top;
}

.promotion__headline {
  font-size: 1.25rem;
  font-weight: 100;
  font-style: italic;
  margin: 0;
}

.promotion__customize {
  display: flex;
}

.promotion__status {
  border: solid 1px rgba(255, 255, 255, 0.2);
  border-radius: 2rem;
  margin-right: 0.66rem;
  line-height: 1.85rem;
  font-weight: bold;
  font-size: 0.875rem;
  font-style: italic;
}

.promotion__count {
  border-left: solid 1px rgba(255, 255, 255, 0.3);
  padding: 0.4rem 1rem 0.4rem 0.6rem;
}

.promotion__status--disabled .promotion__count {
  display: none;
}

.promotion__status-content {
  padding: 0.4rem 1rem;
}

.promotion__upcoming {
  background: #231a3e;
  padding: 1.33rem 1.66rem;
}

.promotion__upcoming-headline {
  font-size: 1rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
}

.promotion__upcoming-name {
  margin: 0;
  font-size: 1rem;
  font-style: italic;
  font-weight: 100;
}

.promotion__upcoming-footer {
  font-size: 0.875rem;
  margin-top: 1.25rem;
  font-weight: 100;
  font-family: 'Montserrat', sans-serif;
}

.promotion__upcoming-footer a {
  color: white;
  font-weight: bold;
  text-decoration: underline;
}

.promotion__upcoming-footer a:hover {
  text-decoration: none;
}

@media screen and (max-width: 1679px) {
  .promotion__status-content span {
    display: none;
  }
  .promotion__status-content:after {
    content: '✓';
  }
  .promotion__headline {
    font-size: 1.125rem;
  }
  .promotion__upcoming-footer {
    font-size: 0.75rem;
  }
}

@media screen and (max-width: 1279px) {
  .promotion__status {
    display: none;
  }
}
