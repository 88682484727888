
.placeholder {
  position:relative;
  width: 100%;
  min-height:1px;
}

.placeholder__text-row,
.placeholder__circle,
.placeholder__line {
  display: inline-block;
  margin: 0 0.25rem;
  margin-bottom: 0.25rem;
  background-color:rgba(255, 255, 255, .05);
  background-image: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.05), transparent);
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  -webkit-animation: 2500ms infinite placeholderShine;
  animation: 2500ms infinite placeholderShine
}

.placeholder__text-row {
  border-radius: 3rem;
  height: 1.875rem;
}

.placeholder__circle {
  border-radius: 3rem;
  height: 1.875rem;
  width: 1.875rem;
}

.placeholder__line {
  display: block;
  border-radius: 3rem;
  height: 1px;
  width: 1.875rem;
  margin: 0;
  margin-bottom: 0.5rem;
}

.placeholder:last-of-type .placeholder__line {
  margin-bottom: 0;
}

@keyframes placeholderShine {
  0% {
    background-position: -1000px 100%;
  }

  100% {
    background-position: calc(100% + 1000px) 100%;
  }
}

@media only screen and (max-width: 1679px) {
  .placeholder__text-row {
    height: 1.66rem;
  }
  .placeholder__circle {
    width: 1.66rem;
    height: 1.66rem;
  }
}
