.loading_container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #10062d;
}

.loading_container .loading-wave {
  height: 2rem;
}

.loading_container .loading-wave .loading-rect {
  background-color: white;
  height: 100%;
  width: 0.45rem;
  margin: 0 0.1rem;
  display: inline-block;
  animation: loadingDelay 1.2s infinite ease-in-out;
}

.loading_container .loading-wave .loading-rect1 {
  animation-delay: -1.2s;
}

.loading_container .loading-wave .loading-rect2 {
  animation-delay: -1.1s;
}
.loading_container .loading-wave .loading-rect3 {
  animation-delay: -1s;
}
.loading_container .loading-wave .loading-rect4 {
  animation-delay: -0.9s;
}
.loading_container .loading-wave .loading-rect5 {
  animation-delay: -0.8s;
}

@keyframes loadingDelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
