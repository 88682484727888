.modal-backdrop {
  background-color: #050015;
}

.modal-backdrop.show {
  opacity: .8;
}

.modal-dialog {
  max-width: 45rem;
  font-weight: 100;
  text-align: center;
}

.modal-dialog a {
  color: white;
  text-decoration: underline;
}

.modal-dialog a:hover {
  text-decoration: none;
}

.modal__close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 1;
  cursor: pointer;
}

.modal__close:hover svg path:last-child {
  fill: #ff0046;
}

.modal__content {
  text-align: left;
}

.modal__content ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.modal__content ul li {
  display: flex;
  margin-bottom: 0.8rem;
}

@media only screen and (min-width: 40rem) {
  .modal__content {
    display: flex;
  }
  .modal__content ul {
    width: 50%;
    margin-left: 0.5rem;
  }
  .modal__content ul li:last-child {
    margin-bottom: 0;
  }
}

.modal__content ul:first-child {
  margin: 0;
}

.modal__list_content {
  display: inline-block;
  margin-left: 0.5rem;
  width: calc(100% - 2.5rem);
}

.modal_buttonGroup {
  margin-top: 2.5rem;
  justify-content: center;
  display: flex;
}

.modal_buttonGroup + p {
  margin-top: 2.5rem;
}

.modal_buttonGroup .btn__link {
  align-self: center;
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: bold;
  display: inline-block;
  margin: 0 0.5rem;
  font-style: italic;
  text-decoration: underline !important;
  cursor: pointer;
}

.modal_buttonGroup .btn__link:hover {
  text-decoration: none !important;
}

.modal-content {
  color: white;
  background: url('/public/assets/images/modal/modal-background.png');
  background-repeat: no-repeat;
  background-size: cover;
  border-color: transparent;
  padding: 2.5rem;
}

.modal-content p {
  margin-bottom: 0.5rem;
}

.modal-header,
.modal-body {
  border: 0;
}

.modal-title {
  padding: 2rem 0;
  width: 100%;
  font-size: 3rem;
  line-height: 3rem;
  text-transform: uppercase;
  font-weight: 100;
  font-style: italic;
  text-align: center;
}

.modal-title strong {
  display: block;
  font-weight: bold;
}
