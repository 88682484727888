.blocked {
  min-height: 100vh;
}

.blocked__text {
  font-size: 1.2rem;
  margin: 2rem 0 0 0;
} 

.blocked__btn {
  margin: 2.5rem 0 1rem 0 !important;
}

.blocked a {
  text-decoration: underline;
  color: white;
}

.blocked a:hover {
  text-decoration: none;
}
