.volume {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  position: relative;
}

.volume__btn--mute {
  cursor: pointer;
}

.volume__btn {
  width: 1rem;
  height: 1rem;
  display: flex;
}

.volume__btn--mute {
  margin-right: 1.2rem;
}

.volume__btn--loud {
  margin-left: 1.2rem;
} 

.volume--light svg path {
  fill: white;
}

.volume .rc-slider .rc-slider-track {
  background-color: #ff0046;
  border-radius: 0.125rem;
  height: 2px;
}

.volume .rc-slider .rc-slider-rail {
  background-color: rgba(0, 0, 0, .1);
  height: 2px;
}

.volume--light .rc-slider .rc-slider-rail {
  background-color: rgba(255, 255, 255, .1);
}

.volume .rc-slider .rc-slider-handle {
  border: 0px;
  width: 1rem;
  height: 1rem;
  left: -0.5rem;
  top: 0.2rem;
  background-color: #ff0046;
}

.volume--light .rc-slider .rc-slider-handle {
  background-color: #fff;
}

.volume.rc-slider .rc-slider-handle:active {
  border: 0px;
}
