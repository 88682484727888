
.station__item {
  padding: 0.6rem;
  padding-right: 0;
  margin: 0.2rem 0;
}

.station__item:hover {
  border-radius: 0.25rem;
  background: linear-gradient(to right, rgba(255,255,255, 0.1) 70%,rgba(255,255,255,0) 100%); 
}

.station__item--active {
  background: linear-gradient(to right, rgba(255,0,68, 0.1) 70%,rgba(255,0,68,0) 100%) !important; 
}

.station__item-table {
  display: table;
  table-layout: fixed;
  cursor: pointer;
  position: relative;
}

.station__item-cell {
  display: table-cell;
  vertical-align: middle;
}

.station__item-image {
  width: 5.5rem;
  height: 5.5rem;
  position: relative;
  background-color: #09021e;
  border-color: rgba(255,255,255,.2);
}

.station__item-image svg {
  display: none;
}

.station__item:hover .station__item-image img {
  opacity: 0.4;
}

.station__item:hover .station__item-image svg {
  display: block;
}

.station__item--active .station__item-image {
  background: #ff0046;
}

.station__item--active .station__item-image svg {
  display: block;
}

.station__item-image img {
  display: block;
  position: absolute;
  margin: auto;
  bottom: -200%;
  top: -200%;
  object-fit: cover;
  height: 100%;
}

.station__item--active img {
  display: none;
}

.station__item-image svg {
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.station__item-title {
  margin-bottom: 0.1875rem;
  font-family: 'Exo 2',sans-serif;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
}

.station__item-genres {
  padding: 0;
  margin: 0.5rem 0 0 -0.5rem;
  list-style: none;
  font-size: 0;
}

.station__item-genres__item {
  display: inline-block;
  margin-left: 0.3125rem;
  margin-top: 0.275rem;
  padding: 0.32rem 0.625rem 0.4rem 0.625rem;
  font-size: 0.58rem;
  line-height: 0.7rem;
  font-weight: 100;
  font-style: italic;
  font-size: 0.6875rem;
  text-transform: uppercase;
  border-radius: 1rem;
  border: solid 0.1rem rgba(255,255,255,.1)
}

.station__item-genres__item--explicit,
.station__item-genres__item--custom {
  background: rgba(255, 255, 255, 1);
  border: solid 0.1rem rgba(255, 255, 255, 1);
  color: #0a0028;
  font-weight: bold;
}

.station__item-genres__item--custom,
.station__item-genres__item--ads {
  background: #ff0044;
  border-color: #ff0044;
  color: rgba(255, 255, 255, 0.95);
}

@media screen and (max-width: 1679px) {
  .station__item {
    padding: 1rem;
    padding-right: 0;
    margin: 0.2rem 0;
  }
  .station__item-title {
    margin-bottom: 0;
  }
  .station__item-image {
    width: 5rem;
    height: 5rem;
  }
  .station__item {
    margin-left: -0.5rem;
    padding: 0.625rem;
    padding-right: 0;
  }
}
