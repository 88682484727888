.recently-added__title {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-style: italic;
  font-weight: 100;
  padding-bottom: 0.7rem;
}

.recently-added__title svg {
  margin-right: 0.5rem;
  top: -0.15rem;
  position: relative;
}

.recently-added__placeholder {
  margin-bottom: -0.35rem;
}

.recently-added__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.recently-added__list-short {
  padding-top: 0.33rem;
}

.recently-added__item {
  position: relative;
  border-radius: 3rem;
  padding: 0.4375rem 0.5625rem;
  margin-bottom: 0.47rem;
}

.recently-added__item:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
}

.recently-added__item:after {
  position: absolute;
  content: "";
  width: 100%;
  bottom: -0.3rem;
  left: 0;
  display: block;
  border-bottom: solid 1px rgba(255, 255, 255, .1);
}


.recently-added__list-short .recently-added__item:first-of-type:before {
  position: absolute;
  content: "";
  width: 100%;
  top: -0.15rem;
  left: 0;
  display: block;
  border-top: solid 1px rgba(255, 255, 255, .1);
}

.recently-added__list ul:first-child .recently-added__item:last-child:before {
  border-top: 0;
}

.recently-added__item-content {
  width: calc(100% - 8rem);
  white-space: nowrap;
  display: inline-block;
  font-style: italic;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  font-weight: 100;
}

.recently-added__item-artist {
  font-weight: bold;
}

.recently-added__item-time {
  font-size: 0.75rem;
  text-align: right;
  width: 7.2rem;
  display: inline-block;
  font-weight: 100;
}

.recently-added__item-content svg {
  margin-right: 0.875rem;
  margin-top: -0.15rem;
}

.recently-added__item-content svg path:nth-of-type(1) {
  stroke: #00d7fe;
}

.recently-added__item:hover .recently-added__item-content svg path:nth-of-type(2) {
  fill: #00d7fe;
}

.recently-added__show-more {
  font-size: 0.875rem;
  width: 100%;
  display: flex;
  cursor: pointer;
  text-decoration: underline !important;
  padding-left: 3.1rem;
  padding-top: 1rem;
  margin-left: 0.5rem;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
}

.recently-added__show-more:hover {
  text-decoration: none !important;
}

@media only screen and (max-width: 1679px) {
  .recently-added__list-short .recently-added__item:first-of-type:before {
    top: -0.275rem;
  }
  .recently-added__title {
    font-size: 1.125rem;
  }

  .recently-added__item-content {
    font-size: 0.875rem;
    line-height: 1.8rem;
  }

  .recently-added__item {
    padding: 0.29rem;
    margin-bottom: 0.50rem;
  }

  .recently-added__show-more {
    font-size: 0.75rem;
    padding-left: 2.7rem;
    margin-left: 0rem;
  }
  .recently-added__placeholder {
    margin-bottom: -0.29rem;
  }
}
