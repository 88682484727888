.player__controls {
  padding: 0;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.player__controls li {
  display: inline-block;
  padding: 0 0.875rem;
}

.player__controls__btn {
  display: block;
  width: 3.5rem;
  height: 3.5rem;
  position: relative;
  background: 0 0;
  border: 0.1875rem solid #ff0046;
  border-radius: 5rem;
}

.player__controls__btn:hover {
  cursor: pointer;
}

.player__controls__btn:focus {
  outline: 0;
}

.player__controls__btn svg {
  width: 1.25rem;
  height: 1.125rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
}

.player__controls__btn:hover svg path {
  fill: #ff0046;
}

.player__controls__btn--play {
  width: 7rem;
  height: 7rem;
  border-width: 0.25rem;
}

.player__controls__btn--play svg {
  height: 3.75rem;
  width: 2.5rem;
}

@media only screen and (max-width: 1679px) {
  .player__controls {
    margin-top: 1.65rem;
  }
}
