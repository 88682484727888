body {
  color: #fff;
  background-color: #10062d;
  font-family: 'Exo 2', sans-serif;
}

.layout {
  overflow: hidden;
}

.btn-primary,
.btn-primary:focus {
  z-index: 1;
  background: #ff0046;
  border: solid transparent;
  font-size: 0.9rem;
  border-radius: 5rem;
  padding: 0.1rem 1rem;
  text-transform: uppercase;
  font-weight: bold;
  font-style: italic;
  border-color: transparent;
  box-shadow: none;
  margin: 0;
}

.btn-primary--green,
.btn-primary--green:focus {
  background: #c8ef3e;
  color: #201f23;
}

.btn-primary:hover,
.btn-primary:active {
  color: #0a0028;
  border-color: transparent;
  box-shadow: none;
  background-color: white;
}

.btn-primary.btn-lg {
  padding: .8rem 1.5rem;
  font-size: 1.1rem;
  line-height: 1.1;
}

.btn-primary.btn-sm {
  padding: .3rem 0.6rem;
  font-size: 0.875rem;
  line-height: 1rem;
  font-weight: 100;
}

.btn-primary strong {
  font-weight: bold;
}

strong {
  font-weight: bold;
}

figure {
  margin: 0 1rem 0 0 !important;
}


/* popup */
.Toastify__toast--info {
  background: transparent;
}
.alert-info {
  background: #231a3e;
  border: solid 1px rgba(0, 0, 0, 0.3);
  color: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  font-weight: 100;
  font-style: italic;
  font-size: 1rem;
}

.alert-info p {
  width: 100%;
  margin: 0;
}

.alert-info svg path:first-child {
  stroke: white;
}

.main {
  padding: 0 1.25rem;
}

.sidebar {
  padding: 0;
  border-left: solid 1px rgba(255, 255, 255, .1);
}

.widget {
  padding: 2.5rem;
  border-bottom: solid 1px rgba(255, 255, 255, .1);
}

.stationbar {
  background: rgba(0,0,0,0.3);
}

@media screen and (max-width: 1679px) {
  .widget {
    padding: 1.25rem;
    padding-top: 1.5rem;
  }
}

@media only screen and (min-width: 768px) {
  .Toastify__toast-container{
    width: 40rem;
    margin-left: -20rem;
  }
}

