.player-header {
  margin-bottom: 3rem;
}

.player-header__artist {
  font-size: inherit;
  line-height: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.875rem;
  font-style: italic;
}

.player-header__title {
  margin-bottom: 0.6875rem;
  text-transform: none;
  line-height: 1.75rem;
  font-size: 1.5625rem;
  font-weight: 100;
  font-style: italic;
}

.player-header__artist::after {
  content: "";
  display: block;
  width: 4rem;
  height: 0.125rem;
  margin: 0.825rem auto;
  border-radius: 0.0625rem;
  background: #ff0046;
}

.player-header {
  text-align: center;
}

@media screen and (max-width: 1679px) {
  .player-header__artist {
    font-size: 1.5625rem;
  }
  .player-header__title {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
  .player-header__artist::after {
    margin: 0.425rem auto;
  }
}