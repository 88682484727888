.upcoming {
  padding: 1.5rem;
}

.upcoming__list {
  padding: 0;
}

@media screen and (max-width: 1679px) {
  .upcoming {
    padding: 0;
  }
}