.upcoming-item__container {
  list-style-type: none;
  padding: 0.25rem 0;
  margin: 0;
}

.upcoming-item__container:after {
  content: "";
  width: 100%;
  height: 0.275rem;
  display: block;
  float: right;
  border-bottom: solid 1px rgba(255, 255, 255, .1);
}

.upcoming-item__container:first-of-type:before {
  content: "";
  width: 100%;
  position: absolute;
  top: -0.1rem;
  display: block;
  border-top: solid 1px rgba(255, 255, 255, .1);
}

.upcoming-item {
  position: relative;
  border-radius: 3rem;
  padding: 0.3125rem;
  font-size: 1rem;
  line-height: 1rem;
}

.upcoming-item:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.1);
}

.upcoming-item:after {
  position: absolute;
  bottom: -0.75rem;
  left: 1.2rem;
  transform: translateY(-2.6rem);
  animation: grow-connector 0.3s;
  animation-fill-mode: forwards;
  width: 0.2rem;
}

.upcoming-item__container--topsong + .upcoming-item__container--topsong .upcoming-item:after {
  content: '';
  background: #00d7fe;
}

.upcoming-item__container--motivation + .upcoming-item__container--motivation .upcoming-item:after,
.upcoming-item__container--message + .upcoming-item__container--message .upcoming-item:after,
.upcoming-item__container--promotion + .upcoming-item__container--promotion .upcoming-item:after,
.upcoming-item__container--gymname + .upcoming-item__container--gymname .upcoming-item:after {
  content: '';
  background: #c8ef3e;
}

.upcoming-item__image {
  display: inline-block;
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1rem;
  vertical-align: middle;
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 5rem;
}

.upcoming-item:hover .upcoming-item__image {
  background: #0a0028;
}

.upcoming-item__image svg {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  bottom: 0;
  top: 0;
}

.upcoming-item__image--playing,
.upcoming-item__image--message,
.upcoming-item__image--promotion,
.upcoming-item__image--gymname {
  background: #c8ef3e;
}

.upcoming-item__image--topsong {
  background: #00d7fe;
}

.upcoming-item:hover .upcoming-item__image--topsong {
  background: #00d7fe;
}

.upcoming-item__image--motivation,
.upcoming-item__image--gymname-motivation,
.upcoming-item:hover .upcoming-item__image--motivation,
.upcoming-item:hover .upcoming-item__image--gymname-motivation {
  background: white;
}

.upcoming-item:hover .upcoming-item__image--playing,
.upcoming-item:hover .upcoming-item__image--message,
.upcoming-item:hover .upcoming-item__image--promotion,
.upcoming-item:hover .upcoming-item__image--gymname {
  background: #c8ef3e;
}

.upcoming-item__title {
  width: calc(100% - 7.2rem);
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
  font-style: italic;
  overflow: hidden;
  font-weight: 100;
  text-overflow: ellipsis;
}

.upcoming-item .icon {
  vertical-align: top;
  margin-left: 0.5rem;
}

.upcoming-item .icon svg {
  width: 1.56rem;
  height: 1.56rem;
  visibility: hidden;
}

.upcoming-item:hover .icon svg {
  visibility: visible;
}

.upcoming-item:hover .icon:hover svg path:last-child {
  fill: #ff0044;
}

.upcoming-item__image--message svg path,
.upcoming-item__image--topsong svg path,
.upcoming-item__image--promotion svg path,
.upcoming-item__image--motivation svg path,
.upcoming-item__image--gymname svg path {
  fill: #201f23;
}

@keyframes grow-connector {
  0%   { height: 0; z-index: 0; }
  50% { height: 0; z-index: 0; }
  100% { height: 2rem; z-index: 1; }
}


@media screen and (max-width: 1679px) {
  .upcoming-item {
    border-radius: 1rem;
    padding: 0.3125rem;
    font-size: 0.875rem;
    line-height: 0.875rem;
  }
  .upcoming-item__title {
    width: calc(100% - 5.9rem);
  }
  .upcoming-item__image {
    margin-right: 0.625rem;
    width: 1.5rem;
    height: 1.5rem;
  }
  .upcoming-item .icon {
    margin-left: 0.3125rem;
  }
  .upcoming-item:after {
    left: 1rem;
  }
  .upcoming-item__container:after {
    height: 0.305rem;
  }
  @keyframes grow-connector {
    0%   { height: 0; z-index: 0; }
    50% { height: 0; z-index: 0; }
    100% { height: 1.2rem; z-index: 1; }
  }  
}
